import cn from 'classnames';
import React from 'react';

import { Loader, Text } from '@anchorage/common/dist/components';
import { CompleteIcon } from '@anchorage/common/dist/components/Icons';
import { useDeepCompare } from '@anchorage/common/dist/hooks';

import useCurrentUser from 'hooks/useCurrentUser';

import { useGetDAppInfoQuery } from 'generated/graphql';

import css from './styles.module.scss';

import ExternalBadge from '../../../shared/ExternalBadge';

type Props = {
  dappID: string;
};

const DAppBanner = ({ dappID }: Props) => {
  const { isPortoOrg } = useCurrentUser();

  const { error, loading, data } = useGetDAppInfoQuery({
    skip: !dappID,
    variables: {
      dappID,
    },
  });

  const dAppInfo = React.useMemo(
    () => data?.getDAppInfo.dapp,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([data]),
  );

  const emptyStateStyles = cn({
    [css.emptyState!]: true,
    [css.banner!]: true,
  });

  if (loading) {
    return (
      <div className={emptyStateStyles}>
        <Loader />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className={emptyStateStyles}>
        <Text size="small" className={css.errorText}>
          There was an error loading the app info.
        </Text>
      </div>
    );
  }

  return (
    <div className={css.banner}>
      <div className={css.bannerContent}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={dAppInfo?.iconURL || ''}
          alt={dAppInfo?.name}
          className={css.appIcon}
        />
        <div className={css.bannerTextSection}>
          <Text>{dAppInfo?.name}</Text>
          <Text size="small" className={css.subtitle}>
            {dAppInfo?.url}
          </Text>
          {!isPortoOrg && (
            <Text size="small" className={css.verification}>
              <CompleteIcon className={css.checkIcon} /> Verified by Anchorage
              Digital
            </Text>
          )}
        </div>
      </div>

      <ExternalBadge />
    </div>
  );
};

export default DAppBanner;
