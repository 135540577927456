// Styles
import classnames from 'classnames';
import { noop } from 'lodash';
import React, { useCallback, useRef } from 'react';

// Components
import { FileUploadBase, FilesList, UploadedFile } from './_components';
import { Props as FileUploadProps } from './_components/FileUploadBase';
// Types
import { Props as FilesListProps } from './_components/FilesList';

// Utils
import { convertFileListToArray } from './utils';

import styles from './index.css';

export type Props = FilesListProps & FileUploadProps;

export { convertFileListToArray, FilesList, FileUploadBase, UploadedFile };

const FileUpload = (props: Props): JSX.Element => {
  const { className = '', uploadedFiles, onDelete = noop } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleOnDelete = useCallback(
    (index: number) => {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      onDelete(index);
    },
    [fileInputRef, onDelete]
  );

  let maybeFilesList;
  if (uploadedFiles) {
    maybeFilesList = (
      <FilesList uploadedFiles={uploadedFiles} onDelete={handleOnDelete} />
    );
  }

  const wrapperClasses = classnames({
    [styles.wrapper]: true,
    [className]: Boolean(className),
  });

  return (
    <div className={wrapperClasses}>
      <FileUploadBase {...props} fileInputRef={fileInputRef} />
      {maybeFilesList}
    </div>
  );
};

export default FileUpload;
