import React from 'react';

export default function CollateralDashboardIcon(props: SVGProps): JSX.Element {
  const { title = 'CollateralDashboard', ...restProps } = props;

  return (
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 39C30.4934 39 39 30.4934 39 20C39 9.50659 30.4934 1 20 1C9.50659 1 1 9.50659 1 20C1 30.4934 9.50659 39 20 39ZM20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7194 11.5544C20.3125 11.1815 19.6875 11.1815 19.2806 11.5544L17.153 13.5044C16.9335 13.7055 16.8085 13.9894 16.8085 14.2869V16.4034C16.8085 16.99 17.2848 17.4656 17.8723 17.4656H22.1277C22.7152 17.4656 23.1915 16.99 23.1915 16.4034V14.2869C23.1915 13.9894 23.0665 13.7055 22.847 13.5044L20.7194 11.5544ZM18.4174 10.6153C19.3125 9.7949 20.6875 9.7949 21.5826 10.6153L23.7103 12.5653C24.1932 13.0079 24.4681 13.6324 24.4681 14.2869V16.4034C24.4681 17.694 23.4202 18.7403 22.1277 18.7403H17.8723C16.5798 18.7403 15.5319 17.694 15.5319 16.4034V14.2869C15.5319 13.6324 15.8068 13.0079 16.2897 12.5653L18.4174 10.6153ZM24.307 19.9634L26.1123 21.7661L25.2097 22.6674L23.4043 20.8648L24.307 19.9634ZM16.4862 20.8648L14.6809 22.6674L13.7782 21.7661L15.5835 19.9634L16.4862 20.8648ZM10 25.9635C10 24.6729 11.0478 23.6266 12.3404 23.6266H14.0426C15.3351 23.6266 16.383 24.6729 16.383 25.9635V27.6631C16.383 28.9537 15.3351 30 14.0426 30H12.3404C11.0478 30 10 28.9537 10 27.6631V25.9635ZM12.3404 24.9013C11.7529 24.9013 11.2766 25.3768 11.2766 25.9635V27.6631C11.2766 28.2497 11.7529 28.7253 12.3404 28.7253H14.0426C14.6301 28.7253 15.1064 28.2497 15.1064 27.6631V25.9635C15.1064 25.3768 14.6301 24.9013 14.0426 24.9013H12.3404ZM23.617 25.9635C23.617 24.6729 24.6649 23.6266 25.9574 23.6266H27.6596C28.9522 23.6266 30 24.6729 30 25.9635V27.6631C30 28.9537 28.9522 30 27.6596 30H25.9574C24.6649 30 23.617 28.9537 23.617 27.6631V25.9635ZM25.9574 24.9013C25.3699 24.9013 24.8936 25.3768 24.8936 25.9635V27.6631C24.8936 28.2497 25.3699 28.7253 25.9574 28.7253H27.6596C28.2471 28.7253 28.7234 28.2497 28.7234 27.6631V25.9635C28.7234 25.3768 28.2471 24.9013 27.6596 24.9013H25.9574Z"
      />
    </svg>
  );
}
