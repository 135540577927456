import type { OperationDrawer } from 'generated/graphql';

import TrustedDestinationAsset from '../../../shared/TrustedDestinationAsset';
import DestinationWithAML from '../../../widgets/DestinationWithAML';
import TextSection from '../../../widgets/TextSection';

type AddTrustedDestinationType = Extract<
  OperationDrawer.operation,
  { __typename: 'AddTrustedDestinationOperation' }
>;

type RemoveTrustedDestinationType = Extract<
  OperationDrawer.operation,
  { __typename: 'RemoveTrustedDestinationOperation' }
>;

type Props = {
  operation: AddTrustedDestinationType | RemoveTrustedDestinationType;
};

const TrustedDestination = ({ operation }: Props) => {
  const {
    amlRecord,
    destination: {
      assetTypeInfo: {
        requiresMemo,
        networkInfo,
        assetTypeID,
        abbreviation,
        name: assetName,
        iconURL,
      },
      destination,
      name,
      isAtNetworkLevel,
      trustedDestinationExtraParams,
    },
  } = operation;

  return (
    <>
      <DestinationWithAML
        address={destination}
        addressSectionTitle="Address name"
        blockchainExplorerURL={null}
        destinationNames={[name]}
        amlSubmission={amlRecord?.submission}
        tagValue={trustedDestinationExtraParams?.memo}
        requiresMemo={requiresMemo}
        assetTypeID={assetTypeID}
      />
      <TrustedDestinationAsset
        abbreviation={abbreviation}
        assetName={assetName}
        networkName={networkInfo?.name}
        isNetworkLevel={isAtNetworkLevel}
        iconURL={iconURL}
      />
      {networkInfo ? (
        <TextSection title="Network" content={networkInfo.name} />
      ) : null}
    </>
  );
};

export default TrustedDestination;
