import { AssetTypeID } from 'generated/graphql';

export enum AssetTagType {
  DESTINATION_TAG = 'destinationTag',
  MEMO = 'memo',
}

export const READABLE_ASSET_TAG: { [x in AssetTagType]: string } = {
  [AssetTagType.DESTINATION_TAG]: 'Destination tag',
  [AssetTagType.MEMO]: 'Memo',
};

// memo assets
export const TENDERMINT_MEMO_ASSETS = [
  // Agoric
  AssetTypeID.BLD,
  AssetTypeID.BLD_R,
  AssetTypeID.BLD_T,

  // Allora
  AssetTypeID.ALLO,
  AssetTypeID.ALLO_R,
  AssetTypeID.ALLO_T,

  // Axelar
  AssetTypeID.AXL,
  AssetTypeID.AXL_R,
  AssetTypeID.AXL_T,

  // Evmos
  AssetTypeID.EVMOS,
  AssetTypeID.EVMOS_R,
  AssetTypeID.EVMOS_T,

  // Oasis uses tendermint as the consensus layer, but does not support the memo field

  // Osmosis
  AssetTypeID.OSMO,
  AssetTypeID.OSMO_R,
  AssetTypeID.OSMO_T,

  // Provenance
  AssetTypeID.HASH,
  AssetTypeID.HASH_R,
  AssetTypeID.HASH_T,

  // Rarimo
  AssetTypeID.RMO,
  AssetTypeID.RMO_R,
  AssetTypeID.RMO_T,

  // Sei
  AssetTypeID.SEI,
  AssetTypeID.SEI_R,
  AssetTypeID.SEI_T,

  // dYDx
  AssetTypeID.DYDX_CHAIN,
  AssetTypeID.DYDX_CHAIN_R,
  AssetTypeID.DYDX_CHAIN_T,

  // tia
  AssetTypeID.TIA,
  AssetTypeID.TIA_R,
  AssetTypeID.TIA_T,
];

export const STELLAR_MEMO_ASSETS = [
  AssetTypeID.XLM,
  AssetTypeID.XLM_R,
  AssetTypeID.XLM_T,
];
export const COSMOS_MEMO_ASSETS = [
  AssetTypeID.ATOM,
  AssetTypeID.ATOM_R,
  AssetTypeID.ATOM_T,
  AssetTypeID.ATOM_SR,
  AssetTypeID.ATOM_ST,
];

export const MEMO_ASSETS = [
  ...TENDERMINT_MEMO_ASSETS,
  ...STELLAR_MEMO_ASSETS,
  ...COSMOS_MEMO_ASSETS,
];

// destination tag assets
export const RIPPLE_ASSETS = [
  AssetTypeID.XRP,
  AssetTypeID.XRP_R,
  AssetTypeID.XRP_T,
];

export const ETH_ASSETS = [
  AssetTypeID.ETHTEST,
  AssetTypeID.ETH,
  AssetTypeID.ETH_R,
  AssetTypeID.ETHHOL,
  AssetTypeID.ETHSEP,
  AssetTypeID.ETHDEV,
  AssetTypeID.ETH_TG,
  AssetTypeID.ETH_TR,
];

export const BTC_ASSETS = [
  AssetTypeID.BTC,
  AssetTypeID.BTC_R,
  AssetTypeID.BTC_T,
];

export const DESTINATION_TAG_ASSETS = [...RIPPLE_ASSETS];

export const ASSETS_WITH_TAGS = [...MEMO_ASSETS, ...DESTINATION_TAG_ASSETS];

export const TAG_LABELS = {
  [AssetTagType.MEMO]: 'Memo',
  [AssetTagType.DESTINATION_TAG]: 'Destination tag',
};
