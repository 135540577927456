import useCurrentUser from 'hooks/useCurrentUser';

import { IAMPermissionAction, IAMPermissionEdge } from 'generated/graphql';

const useHasOrgLevelPermissions = (
  requestedPermissions: IAMPermissionAction[],
) => {
  const {
    organization: {
      iam: {
        permissions: { edges },
      },
    },
  } = useCurrentUser();
  const pEdges = edges as IAMPermissionEdge[];
  return requestedPermissions.map((permission) => {
    return pEdges.some((edge) => edge?.node?.action === permission);
  });
};

export default useHasOrgLevelPermissions;
