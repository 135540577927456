import { useContext, useMemo } from 'react';

import { AnalyticsProps, AnalyticsPropsType } from '../types';

import { AnalyticsContext } from './AnalyticsProvider';

// Initially the events were difined in an events file.
// We hit a problem while generating the type declaration files
// where the events file and this file would be out of sync and brake CI.
// It was non-deterministic.
// We merge the files and we're declaring types here as a mitigation.
export type EventName =
  // web vitals
  | 'client_dashboard:web_vitals:largest_contentful_paint'
  | 'client_dashboard:web_vitals:first_contentful_paint'
  | 'client_dashboard:web_vitals:time_to_interactive:dashboard_vaults'

  // shared components
  | 'client_dashboard:filters:hotkey_search_input:focused'
  | 'client_dashboard:scroll_to_top_button:clicked'

  // navigation
  | 'navigation:client_dashboard:app_loaded'
  | 'navigation:client_dashboard:page_view'
  | 'navigation:client_dashboard:sidenav_menuheader_clicked'
  | 'navigation:backoffice_dashboard:app_loaded'
  | 'navigation:backoffice_dashboard:page_view'

  // Product Updates
  | 'product_updates:drawer:opened'
  | 'product_updates:drawer:closed'
  | 'product_updates:important_update:displayed'
  | 'product_updates:important_update:closed'
  | 'product_updates:important_update:done'
  | 'product_updates:important_update:next'
  | 'product_updates:important_update:link_clicked'

  // Create Vault Wizard
  | 'create_vault_wizard:opened'
  | 'create_vault_wizard:next_clicked' // use initiationPage property to see the trigger source
  | 'create_vault_wizard:form_completed' // use status property for failed or successful

  // Operation Detail Page
  | 'operation_detail:vault_policies:accordion_clicked'
  | 'operation_detail:admin_policies:accordion_clicked'

  // Organization Page
  | 'organization_page:policies:drawer_tab_viewed'

  // Activity Page
  | 'activity_page:table:download_button_clicked'

  // Statements Page
  | 'statements_page:table:pdf_link_clicked'

  // Reports Page
  | 'reports_page:asset_addresses:csv_button_clicked'
  | 'reports_page:daily_balance:csv_button_clicked'
  | 'reports_page:daily_balance_by_vaults:csv_button_clicked'
  | 'reports_page:daily_balance_by_vault_addresses:csv_button_clicked'

  // Trade Page
  | 'trade_page:trades_table:download_button_clicked'

  // Wallets drawer
  | 'wallets:qr_button_clicked' // clicked button to expand QR code
  | 'wallets:search_input:focused'
  | 'wallets:wallet_card:archive_button_clicked'

  // Archive wallets modal
  | 'archive_wallets_modal:opened'
  | 'archive_wallets_modal:action_button_clicked'

  // --- Withdrawals web ---
  | 'withdrawals:drawer:opened' // clicked on Withdraw button in
  | 'withdrawals:drawer:closed'
  | 'withdrawals:drawer:back_clicked' // use initiationPage property to set the page it was clicked
  | 'withdrawals:drawer:continue_clicked' // use initiationPage property to set the page context
  | 'withdrawals:tag_confirmation_modal:skip' // use tagType property to set Memo os Destination tag
  | 'withdrawals:tag_confirmation_modal:add_tag' // use tagType property to set Memo os Destination tag
  // use action property to set if clicked on X or add
  | 'withdrawals:withdrawal:form_completed' // use status property for failed or success

  // IAM
  | 'iam:roles:download_structure'

  // Trusted destinations frontoffice
  | 'trusted_dest:add_drawer:opened'
  | 'trusted_dest:add_drawer:closed'
  | 'trusted_dest:add_drawer:back_clicked'
  | 'trusted_dest:add_drawer:continue_clicked'
  | 'trusted_dest:add_drawer:form_completed' // use status property for failed or successful
  | 'trusted_dest:view_drawer:opened'
  | 'trusted_dest:view_drawer:closed'
  | 'trusted_dest:view_drawer:back_clicked'
  | 'trusted_dest:view_drawer:continue_clicked'
  | 'trusted_dest:view_drawer:remove_form_completed'
  | 'trusted_dest:view_drawer:remove_button_clicked'

  // Deposit attributions survey
  | 'deposit_attributions:survey:submitted'
  | 'deposit_attributions:survey:closed'

  // ETH Staking
  | 'eth_staking:wizard:opened'
  | 'eth_staking:continue:clicked'
  | 'eth_staking:wizard:flow_completed'

  // Standardized Staking
  | 'standardized_staking:wizard:opened'
  | 'standardized_staking:continue:clicked'
  | 'standardized_staking:wizard:flow_completed'
  // used on the stake flow wizard if auto compound is available
  | 'standardized_staking:claim_rewards:toggled';

export const useAnalytics = () => useContext(AnalyticsContext);

/**
 * Hook to send analytics events with the correct platform and context
 */
export const useAnalyticsProps = ({
  platform,
  context,
  debug = false,
}: AnalyticsProps): AnalyticsPropsType => {
  const { track } = useAnalytics();

  const analyticsProps = useMemo(
    () => ({
      analyticsFn: (eventName: string, payload?: object) => {
        const analyticsEventPayload = {
          name: `${platform}:${context}:${eventName}`,
          ...(payload ? { data: payload } : {}),
        };
        if (debug) {
          console.info(analyticsEventPayload);
        } else {
          track(analyticsEventPayload);
        }
      },
    }),
    [platform, context, debug, track],
  );

  return analyticsProps;
};
