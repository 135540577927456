import { InAppSurveyContext } from 'context/InAppSurvey';
import { useContext } from 'react';

import { EventName, useAnalytics } from '@anchorage/analytics';

import useCurrentUser from 'hooks/useCurrentUser';

import {
  InAppSurveyInitializeProps,
  UseInAppSurveyReturnValue,
} from './_types';

import {
  getHasSurveyBeenTaken,
  getShouldShowSurvey,
  registerSurveyClosed,
  registerSurveyShown,
  registerSurveyTaken,
} from './localStorageHelpers';

// eslint-disable-next-line import/no-anonymous-default-export
export default (): UseInAppSurveyReturnValue => {
  const {
    isSurveyOpen,
    setIsSurveyOpen,
    surveySteps,
    setSurveySteps,
    surveyName,
    setSurveyName,
    eventsPrefix,
    setEventsPrefix,
    defaultValues,
    setDefaultValues,
  } = useContext(InAppSurveyContext);
  const { currentUser } = useCurrentUser();
  const { track } = useAnalytics();

  const openSurvey = ({
    steps,
    surveyName,
    defaultFormValues = {},
    eventsPrefix = '',
  }: InAppSurveyInitializeProps) => {
    setSurveySteps(steps);
    setSurveyName(surveyName);
    setEventsPrefix(eventsPrefix);
    setDefaultValues(defaultFormValues);

    if (!getShouldShowSurvey(surveyName, currentUser.email)) {
      setIsSurveyOpen(false);
      return;
    }

    setIsSurveyOpen(true);
    eventsPrefix && track({ name: `${eventsPrefix}:started` as EventName });
    registerSurveyShown(surveyName, currentUser.email);
  };

  const closeSurvey = () => {
    registerSurveyClosed(surveyName, currentUser.email);
    // if survey has been taken we don't want to track the close event
    eventsPrefix &&
      !getHasSurveyBeenTaken(surveyName, currentUser.email) &&
      track({ name: `${eventsPrefix}:closed` as EventName });
    setIsSurveyOpen(false);
  };

  const answerSurvey = (data: any) => {
    eventsPrefix &&
      track({ name: `${eventsPrefix}:submitted` as EventName, data });
    registerSurveyTaken(surveyName, currentUser.email);
  };

  return {
    isSurveyOpen,
    openSurvey,
    closeSurvey,
    answerSurvey,
    surveySteps,
    defaultValues,
  };
};
