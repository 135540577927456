// Utils
import classnames from 'classnames';
import { noop } from 'lodash';
import React, { RefObject, useEffect, useState } from 'react';

import { convertFileListToArray } from '../utils';

import sharedStyles from '../index.css';
// Styles
import styles from './FileUploadBase.css';

import { UploadedFileType } from './_types';

// Components
import { UploadIcon } from '../../Icons';

// Types
export type Props = {
  label?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  multiple?: boolean;
  setUploadedFiles?: (files: UploadedFileType[]) => void;
  readOnly?: true;
  fileInputRef?: RefObject<HTMLInputElement>;
  accept?: string;
};

const FileUploadBase = ({
  className = '',
  disabled,
  id = 'file-upload',
  label,
  multiple = false,
  setUploadedFiles = noop,
  readOnly,
  fileInputRef,
  accept,
}: Props): JSX.Element => {
  const isDisabled = disabled || false;
  const isReadOnly = readOnly || !setUploadedFiles;

  const [isActive, setIsActive] = useState(false);

  const clickInput = (): void => fileInputRef?.current?.click();

  const setIsActiveTrue = (event: DragEvent | void = undefined): void => {
    if (event) {
      event.preventDefault();
    }
    setIsActive(true);
  };

  const setIsActiveFalse = (
    event: DragEvent | React.DragEvent<HTMLButtonElement> | void = undefined
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setIsActive(false);
  };

  const addDragEventListeners = (): void => {
    window.addEventListener('dragenter', setIsActiveTrue);
    window.addEventListener('dragover', setIsActiveTrue);
    window.addEventListener('dragleave', setIsActiveFalse);
    window.addEventListener('drop', setIsActiveFalse);
  };

  const cleanupDragEventListeners = (): void => {
    window.removeEventListener('dragenter', setIsActiveTrue);
    window.removeEventListener('dragover', setIsActiveTrue);
    window.removeEventListener('dragleave', setIsActiveFalse);
    window.removeEventListener('drop', setIsActiveFalse);
  };

  useEffect(() => {
    addDragEventListeners();
    return cleanupDragEventListeners;
  }, []);

  const Label = (): JSX.Element => {
    const labelClasses = classnames({
      [styles.label]: true,
      [styles.disabled]: isDisabled,
    });

    return (
      <label className={labelClasses} htmlFor={id}>
        {label}
      </label>
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.persist();

    const { files } = event.currentTarget;
    if (files && files.length) {
      setUploadedFiles(convertFileListToArray(files));
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLButtonElement>): void => {
    event.persist();
    setIsActiveFalse();

    const { files } = event.dataTransfer;
    if (files && files.length) {
      setUploadedFiles(convertFileListToArray(files));
    }
  };

  const dropBoxClasses = classnames({
    [styles.dropbox]: true,
    [styles.disabled]: isDisabled,
    [styles.active]: isActive && !isDisabled,
  });
  const wrapperClasses = classnames({
    [sharedStyles.wrapper]: true,
    [className]: Boolean(className),
  });

  return (
    <div className={wrapperClasses}>
      {label && <Label />}
      <button
        className={dropBoxClasses}
        disabled={isDisabled || isReadOnly}
        onDrop={handleDrop}
        onClick={clickInput}
        type="button"
      >
        <UploadIcon className={styles.icon} />
        <p>
          Drag and drop here or
          <br />
          <span className={styles.highlight}>browse files</span>
        </p>
      </button>
      <input
        className={styles.hidden}
        disabled={isDisabled}
        id={id}
        onChange={handleChange}
        multiple={multiple}
        ref={fileInputRef}
        type="file"
        readOnly={isReadOnly}
        accept={accept}
      />
    </div>
  );
};

export default FileUploadBase;
