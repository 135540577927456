import BigNumber from 'bignumber.js';
import type { ReactNode } from 'react';
import { createContext, useMemo } from 'react';

import { useDeepCompare } from '@anchorage/common/dist/hooks';

import { alphabeticalSortAssetID } from './utils';

import type {
  AssetTypeInfoFields,
  Country,
  StaticDataFO,
  StaticDataFOQuery as StaticDataResult,
} from 'generated/graphql';

type Disclosure = StaticDataFO.disclosures;
export type DisclosureMap = { [x: string]: Disclosure };
export type AssetNetworkIDMap = {
  [x: string]: AssetTypeInfoFields.networkInfo | null;
};
export type AssetInfoMap = {
  [x: string]: StaticDataFO.assetTypeInfos | null;
};

export type StaticDataContextProps = {
  allAssets: StaticDataFO.assetTypeInfos[];
  assetNetworkIDMap: AssetNetworkIDMap;
  assetInfoMap: AssetInfoMap;
  nativeAssetTypeInfos: StaticDataFO.nativeAssetTypeInfos[];
  disclosures: DisclosureMap;
  dustThreshold: BigNumber;
  countries: Country[];
  refetch: () => Promise<any>;
};

type StaticDataProviderProps = {
  children: ReactNode;
  data?: StaticDataResult;
  refetch?: () => Promise<any>;
};

export const StaticDataContext = createContext<StaticDataContextProps>({
  allAssets: [],
  assetNetworkIDMap: {},
  assetInfoMap: {},
  nativeAssetTypeInfos: [],
  disclosures: {},
  countries: [],
  refetch: async () => {},
  dustThreshold: new BigNumber(0),
});

export function StaticDataProvider({
  children,
  data,
  refetch = async () => {},
}: StaticDataProviderProps) {
  const formattedDisclosures: DisclosureMap = useMemo(
    () => {
      const disclosures = data?.disclosures || [];

      const formattedData: DisclosureMap = {};
      disclosures.forEach((disclosure: Disclosure) => {
        formattedData[disclosure.disclosureID] = disclosure;
      });
      return formattedData;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([data?.disclosures]),
  );

  const sortedAssets = useMemo(
    () => {
      const allAssets = data?.assetTypeInfos || [];
      return [...allAssets].sort((a, b) => alphabeticalSortAssetID(a, b));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([data?.assetTypeInfos]),
  );

  const assetNetworkIDMap = useMemo(
    () => {
      const allAssets = data?.assetTypeInfos || [];
      const assetsMap: AssetNetworkIDMap = {};
      allAssets.forEach((asset) => {
        assetsMap[asset.assetTypeID] = asset.networkInfo;
      });
      return assetsMap;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([data?.assetTypeInfos]),
  );

  const assetInfoMap = useMemo(
    () => {
      const allAssets = data?.assetTypeInfos || [];
      const assetsMap: AssetInfoMap = {};
      allAssets.forEach((asset) => {
        assetsMap[asset.assetTypeID] = asset;
      });
      return assetsMap;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompare([data?.assetTypeInfos]),
  );

  return (
    <StaticDataContext.Provider
      value={{
        dustThreshold: new BigNumber(
          data?.walletFilterData?.dustThreshold || 0,
        ),
        allAssets: sortedAssets,
        assetNetworkIDMap,
        assetInfoMap,
        nativeAssetTypeInfos: data?.nativeAssetTypeInfos || [],
        disclosures: formattedDisclosures,
        countries: data?.standards?.countries || [],
        refetch,
      }}
    >
      {children}
    </StaticDataContext.Provider>
  );
}
