import { CurrentUserProvider } from 'context/CurrentUserProvider';
import InAppSurveyProvider from 'context/InAppSurvey';
import { StaticDataProvider } from 'context/StaticData';
import NextAdapterApp from 'next-query-params/app';
import { FC, PropsWithChildren } from 'react';
import { QueryParamProvider } from 'use-query-params';

import { ORG_OPERATOR_TYPES } from '@anchorage/frontoffice/constants/app';
import { AppUser } from '@anchorage/frontoffice/contexts/AppUser';
import FeatureFlagsProvider, {
  FeatureFlagsBEProvider,
} from '@anchorage/frontoffice/contexts/featureFlags';
import useIsPortoApp from '@anchorage/frontoffice/utils/useIsPortoApp';
import usePortoTheme from '@anchorage/frontoffice/utils/usePortoTheme';

import AppLayout from 'components/AppLayout';
import DeviceChecker from 'components/layouts/App/DeviceChecker';

import {
  Organization,
  PolicySourceOfTruth,
  useAppInfoQuery,
  useStaticDataFOQuery,
} from 'generated/graphql';

import css from '../../../pages/styles.module.css';

import AnalyticsWrapper from './AnalyticsWrapper';
import AppAnalytics from './AppAnalytics';
import LoadingPage from './LoadingPage';

const SideNavLayout: FC<PropsWithChildren> = ({ children }) => {
  const { loading: isLoading, data, error } = useAppInfoQuery() || {};

  const { data: staticData, refetch } = useStaticDataFOQuery();

  // TODO remove this block once porto users are migrated to the Port domain.
  const isOrgPorto = data?.organization.operator === ORG_OPERATOR_TYPES.SELF;
  const isDomainPorto = useIsPortoApp();
  usePortoTheme({
    active: isOrgPorto || isDomainPorto,
  });

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!data || !data.currentUser || error) {
    // TODO improve error message and styling
    return (
      <div>There was an error loading the page data. Please try again</div>
    );
  }

  return (
    <FeatureFlagsProvider
      organizationKeyID={data?.organization.organizationKeyID}
      email={data?.currentUser.email}
      policySourceOfTruth={
        data?.organization.policyInfo?.sourceOfTruth ??
        PolicySourceOfTruth.POLICY_SOT_CONTAINER
      }
    >
      <FeatureFlagsBEProvider features={data.enabledFeatures}>
        <QueryParamProvider adapter={NextAdapterApp}>
          <AppUser>
            <CurrentUserProvider currentUserInfo={data || null}>
              <StaticDataProvider data={staticData} refetch={refetch}>
                <DeviceChecker>
                  <div id="anchorage" className={css.app}>
                    {data && data.currentUser && data.organization ? (
                      <AnalyticsWrapper
                        user={data.currentUser}
                        organization={
                          data.organization as Partial<Organization>
                        }
                      >
                        <InAppSurveyProvider>
                          <AppLayout>
                            <AppAnalytics />
                            {children}
                          </AppLayout>
                        </InAppSurveyProvider>
                      </AnalyticsWrapper>
                    ) : (
                      <AppLayout>{children}</AppLayout>
                    )}
                    <div id="drawer-root" />
                    <div id="modal-root" />
                  </div>
                </DeviceChecker>
              </StaticDataProvider>
            </CurrentUserProvider>
          </AppUser>
        </QueryParamProvider>
      </FeatureFlagsBEProvider>
    </FeatureFlagsProvider>
  );
};

export default SideNavLayout;
