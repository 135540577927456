import React from 'react';

import { BracketsIcon, Circle } from '@anchorage/common/dist/components/Icons';
import { useFeatureFlagsContext } from '@anchorage/feature-flags';

import sharedCss from '../styles.module.scss';
import css from './styles.module.scss';

import DevToolsDrawer from '../../DevToolsDrawer';
import LocalEnvBadge from '../../LocalEnvBadge';

type Props = {
  userEmail: string;
  organizationKeyID: string;
};

const DevToolsMenuItem = ({ userEmail, organizationKeyID }: Props) => {
  const [showDevToolsDrawer, setShowDevToolsDrawer] = React.useState(false);

  const { flagsInLocalStorage } = useFeatureFlagsContext();

  const isAnchorageUser: boolean = [
    '@anchorlabs.com',
    '@anchorage.com',
    '@anchorlabs.dev',
  ].some((suffix) => userEmail.endsWith(suffix));

  const toggleDevToolsDrawer = () => {
    setShowDevToolsDrawer((prevState) => !prevState);
  };

  const hasOverrides = Object.keys(flagsInLocalStorage).length !== 0;

  // This check is needed because porto orgs do not return emails
  const isDevDomain =
    window.location.host === 'dashboard.dev.porto.xyz' ||
    window.location.host.includes('port3000');

  if (!isAnchorageUser && !isDevDomain) {
    return null;
  }

  return (
    <>
      <div className={sharedCss.menuItem} onClick={toggleDevToolsDrawer}>
        <BracketsIcon className={sharedCss.icon} />
        <div className={sharedCss.label}>Dev Tools</div>
        {hasOverrides && <Circle className={css.indicatorIcon} />}
        <LocalEnvBadge />
      </div>
      <DevToolsDrawer
        isOpen={showDevToolsDrawer}
        onClose={toggleDevToolsDrawer}
        organizationKeyID={organizationKeyID}
      />
    </>
  );
};
export default DevToolsMenuItem;
