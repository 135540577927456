import { transformAddresses } from 'components/shared/OperationDrawer/utils/mapAddressToWallets';

import type { OperationDrawer } from 'generated/graphql';

import AddressInfo from '../../../widgets/AddressInfo';
import AmountAndFees from '../../../widgets/AmountAndFees';
import DestinationWithAML from '../../../widgets/DestinationWithAML';
import DisclosureSection from '../../../widgets/DisclosureSection';
import Transaction from '../../../widgets/Transaction';
import { DELEGATE_TYPES, UNDELEGATE_TYPES } from './constants';

type DelegateOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'DelegateOperation' }
>;

type Props = {
  operation: DelegateOperationType;
};

const DelegateOperation = ({ operation }: Props) => {
  const {
    asset: {
      assetTypeInfo: { assetTypeID },
      vault,
    },
    delegateType,
    delegationAddress,
    finalFee,
    maybeAmount,
    sendingBlockchainAddresses,
    maybeTransactionID,
    maybeViewTransactionLink,
  } = operation;

  let amountTitle = 'Amount';
  let addressTitle = 'Address';

  if (delegateType && DELEGATE_TYPES.includes(delegateType)) {
    amountTitle = 'Delegate amount';
    addressTitle = 'Delegate to';
  } else if (delegateType && UNDELEGATE_TYPES.includes(delegateType)) {
    amountTitle = 'Undelegate amount';
    addressTitle = 'Undelegate from';
  }

  return (
    <>
      {delegationAddress && (
        <DestinationWithAML
          assetTypeID={assetTypeID}
          addressSectionTitle={addressTitle}
          address={delegationAddress.destination}
          blockchainExplorerURL={null}
          destinationNames={[delegationAddress.name]}
        />
      )}
      <AddressInfo
        addressSectionTitle="Source"
        addresses={transformAddresses(sendingBlockchainAddresses)}
        vaultName={vault.name}
        accountName={vault.account?.displayName}
      />
      {maybeAmount && (
        <AmountAndFees
          amount={maybeAmount}
          amountTitle={amountTitle}
          fee={finalFee}
          disclosureTitle="Max fee disclosure"
        />
      )}
      <Transaction
        transactionID={maybeTransactionID}
        transactionLink={maybeViewTransactionLink}
      />
      <DisclosureSection assetTypeID={assetTypeID} />
    </>
  );
};

export default DelegateOperation;
