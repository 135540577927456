import { Button, ButtonProps } from '@latitude/button';
import { FC } from 'react';

import { ButtonAction } from '../';

/**
 * Props for the Footer component.
 */
export interface FooterProps {
  /**
   * The leading action button.
   */
  leadingAction?: ButtonAction;

  /**
   * The primary action button.
   */
  primaryAction?: ButtonAction;

  /**
   * The secondary action button.
   */
  secondaryAction?: ButtonAction;

  /**
   * The tertiary action button.
   */
  tertiaryAction?: ButtonAction;

  /**
   * The variant of the buttons.
   */
  variant?: ButtonProps['variant'];
}

/**
 * Footer component of the modal.
 */
export const Footer: FC<FooterProps> = ({
  leadingAction,
  primaryAction,
  secondaryAction,
  tertiaryAction,
}) => {
  return (
    <div
      data-testid="modal-footer"
      className="
        flex-0
        border-t-border-sys-1
        border-color-ref-ui-divider-strong
        mt-spacing-sys-default-16
        px-spacing-sys-default-16
        pt-spacing-sys-default-16
        flex
        justify-between"
    >
      <div>
        <ActionButton
          buttonNumber="leading"
          type="ghost"
          action={leadingAction}
        />
      </div>
      <div className="gap-sizing-sys-size-8 flex">
        {primaryAction && secondaryAction && (
          <ActionButton
            buttonNumber="tertiary"
            type="ghost"
            action={tertiaryAction}
          />
        )}
        {primaryAction && (
          <ActionButton
            buttonNumber="secondary"
            type="outline"
            action={secondaryAction}
          />
        )}
        <ActionButton
          buttonNumber="primary"
          type="filled"
          action={primaryAction}
        />
      </div>
    </div>
  );
};

/**
 * Action button component.
 */
const ActionButton: FC<{
  type: ButtonProps['type'];
  buttonNumber: string;
  action?: ButtonAction;
}> = ({ action, type, buttonNumber }) => {
  if (!action) return null;
  const { label, ...buttonProps } = action;

  return (
    <>
      <Button
        data-testid={`modal-button-action-${buttonProps.variant}-${type}-${buttonNumber}`}
        type={type}
        {...buttonProps}
      >
        {label}
      </Button>
      {buttonNumber === 'tertiary' && (
        <span className="border-r-border-sys-1 border-color-ref-ui-divider-strong" />
      )}
    </>
  );
};
