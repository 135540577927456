import { Banner } from '@anchorage/common/dist/components';

import { transformAddresses } from '../../../../utils/mapAddressToWallets';

import { OperationState } from 'generated/graphql';
import type { OperationDrawer } from 'generated/graphql';

import css from './styles.module.scss';

import AddressInfo from '../../../widgets/AddressInfo';
import AmountAndFees from '../../../widgets/AmountAndFees';
import DestinationWithAML from '../../../widgets/DestinationWithAML';
import TextSection from '../../../widgets/TextSection';
import Transaction from '../../../widgets/Transaction';

type TransferOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'TransferOperation' }
>;

type Props = {
  operation: TransferOperationType;
};

const TransferOperation = ({ operation }: Props) => {
  const {
    amlRecord,
    amountAtEndTime,
    asset: {
      assetTypeInfo: { networkInfo, requiresMemo, assetTypeID },
      vault,
    },
    extraParams,
    finalFeeAtEndTime,
    maybeTransactionID,
    maybeViewTransactionLink,
    receivingBlockchainAddress: {
      blockchainExplorerURL,
      managedAddress,
      address,
    },
    operationState,
    sendingBlockchainAddresses,
  } = operation;

  const isRiskRejected = operationState === OperationState.RISK_REJECTED;
  const networkName = networkInfo?.name;
  const destinationNames = managedAddress
    ? managedAddress.wallets.map((wallet) => wallet.name)
    : [];

  const destinationProps = {
    address,
    amlSubmission: amlRecord?.submission,
    blockchainExplorerURL,
    extraParams,
    requiresMemo,
    isAnchorageVault: !!managedAddress,
    destinationNames,
    assetTypeID,
  };

  return (
    <>
      {isRiskRejected && (
        <Banner
          className={css.rejectedBanner}
          type="warning"
          title="Operation rejected"
        >
          If you think this is a mistake, contact us.
        </Banner>
      )}
      <AmountAndFees
        amount={amountAtEndTime}
        fee={finalFeeAtEndTime}
        hasPriceTimeTooltip
      />
      {networkName && <TextSection content={networkName} title="Network" />}
      <AddressInfo
        addresses={transformAddresses(sendingBlockchainAddresses)}
        vaultName={vault.name}
        accountName={vault.account?.displayName}
      />
      <DestinationWithAML {...destinationProps} />
      <Transaction
        transactionID={maybeTransactionID}
        transactionLink={maybeViewTransactionLink}
      />
    </>
  );
};

export default TransferOperation;
