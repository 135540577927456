import { LocalGasStationIcon } from '@latitude/icons';
import { Text } from '@latitude/text';
import _uniq from 'lodash/uniq';
import React, { useMemo } from 'react';

import { isNotEmpty } from '@anchorage/common/dist/utils/filters';
import type { AttributionPair } from '@anchorage/common/dist/widgets/PendingAttributions/PendingAttributionsCard/_types';
import { DepositAttributionStatus } from '@anchorage/frontoffice/generated/graphql';

import SingleDepositAttributionsBanner from 'components/shared/SingleDepositAttributionsBanner';

import { transformAddresses } from '../../../../utils/mapAddressToWallets';

import {
  type OperationDrawer,
  OperationDrawerDocument,
} from 'generated/graphql';

import AddressInfo from '../../../widgets/AddressInfo';
import AmountAndFees from '../../../widgets/AmountAndFees';
import Attribution from '../../../widgets/Attribution';
import TextSection from '../../../widgets/TextSection';
import Transaction from '../../../widgets/Transaction';

type DepositEventOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'DepositEventOperation' }
>;

type Props = {
  operation: DepositEventOperationType;
};

const DepositEvent = ({ operation }: Props) => {
  const {
    amountDepositAtEndTime,
    asset: {
      assetTypeInfo: { networkInfo, isFiat },
      vault: receivingVault,
    },
    sendingBlockchainAddresses2,
    receivingBlockchainAddress,
    transactionID,
    attributions,
    viewTransactionLink,
    sendingWireData,
    isFromGasStation,
  } = operation;

  const hasPendingDepositAttributions = useMemo(
    () =>
      attributions?.some(
        (attribution) =>
          attribution?.attributionStatus === DepositAttributionStatus.PENDING,
      ),
    [attributions],
  );

  const attributionPairs: AttributionPair[] = useMemo(
    () =>
      attributions?.map((attribution) => {
        return {
          operationID: operation.operationID,
          sourceAddress: attribution?.sourceAddress.address || '',
        };
      }) || [],
    [operation.operationID, attributions],
  );

  const addresses = useMemo(
    () => _uniq(attributionPairs.map((pair) => pair.sourceAddress)),
    [attributionPairs],
  );

  const { account } = receivingVault;
  const networkName = networkInfo?.name;

  const isAnchorageVault = !!receivingBlockchainAddress.managedAddress;

  return (
    <>
      {hasPendingDepositAttributions && (
        <SingleDepositAttributionsBanner
          refetchQuery={OperationDrawerDocument}
          addresses={addresses}
          attributionPairs={attributionPairs}
        />
      )}
      <AmountAndFees
        amount={amountDepositAtEndTime}
        tokenInfo={amountDepositAtEndTime.tokenInfo}
        hasPriceTimeTooltip
      />
      {networkName && <TextSection content={networkName} title="Network" />}
      {isFromGasStation ? (
        <TextSection
          title="From"
          content={
            <Text className="flex gap-1">
              <LocalGasStationIcon className="w-6 h-6" /> Anchorage Digital Gas
              Station
            </Text>
          }
        />
      ) : (
        <AddressInfo
          addressSectionTitle="From"
          addresses={transformAddresses(
            sendingBlockchainAddresses2.map((send) => send.blockchainAddress),
          )}
          trustedCounterpartyName={
            _uniq(
              sendingBlockchainAddresses2
                .map((send) => send.trustedCounterparty?.counterparty?.name)
                .filter(isNotEmpty),
            )[0]
          }
          wireData={isFiat ? sendingWireData : null}
        />
      )}
      <AddressInfo
        addressSectionTitle="Into"
        addresses={transformAddresses(receivingBlockchainAddress)}
        vaultName={receivingVault.name}
        blockchainExplorerURL={receivingBlockchainAddress.blockchainExplorerURL}
        isAnchorageVault={isAnchorageVault}
        accountName={account?.displayName}
      />
      <Transaction
        transactionID={transactionID}
        transactionLink={viewTransactionLink}
      />
      {attributions && !hasPendingDepositAttributions && !!attributions[0] && (
        <Attribution attribution={attributions[0]} />
      )}
    </>
  );
};

export default DepositEvent;
