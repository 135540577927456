import { usePathname } from 'next/navigation';

import {
  ExitIcon,
  PortoLogoIcon,
} from '@anchorage/common/dist/components/Icons';
import { useFeatureFlagsContext } from '@anchorage/feature-flags';

import { useUserSessionManager } from '../../../hooks/useUserSessionManager';

import sharedCss from '../styles.module.scss';

import { MenuItemProps, SideNavProps, TSubMenuHeaderProps } from '../types';

import { FEATURE_FLAGS } from '../../../constants/split';
import CurrentUser from '../CurrentUser';
import DevToolsMenuItem from '../DevToolsMenuItem';
import {
  MENU_ITEMS,
  MenuItemName,
  SUB_MENU_ITEMS,
  SubMenuItemName,
} from '../constants';
import { menuEntriesMapper } from '../helpers';

export const PortoSideNav = ({
  client,
  permissions: { hasConnectedAppsViewPermissions },
  user: { isUserAdmin, secureName, addedTime, userEmail },
  appPrefix = '',
  organization: { name: orgName, operator, organizationKeyID },
}: SideNavProps) => {
  const { logoutUser } = useUserSessionManager(client);

  const currentPath = usePathname();
  const { useIsFeatureActive } = useFeatureFlagsContext();

  const [isPermissionsM3_1Active] = useIsFeatureActive(
    FEATURE_FLAGS.CD_PERMISSIONS_M3_1,
  );

  const [isReportsTabEnabled] = useIsFeatureActive(
    FEATURE_FLAGS.PORTO_REPORTS_TAB,
  );

  const [isWebTopBarActive] = useIsFeatureActive(FEATURE_FLAGS.CD_WTB_1);

  const hasConnectedAppsViewPermissionsOrIsAdmin = isPermissionsM3_1Active
    ? hasConnectedAppsViewPermissions
    : isUserAdmin;

  const topMenuItems: Array<MenuItemProps | TSubMenuHeaderProps> = [
    MENU_ITEMS[MenuItemName.DASHBOARD],
    MENU_ITEMS[MenuItemName.ACTIVITY],
    ...(isReportsTabEnabled ? [MENU_ITEMS[MenuItemName.REPORTS]] : []),
    ...(hasConnectedAppsViewPermissionsOrIsAdmin
      ? [MENU_ITEMS[MenuItemName.CONNECTED_APPS]]
      : []),
  ];

  const bottomMenuItems: Array<MenuItemProps | TSubMenuHeaderProps> = [
    {
      ...MENU_ITEMS[MenuItemName.SETTINGS],
      subMenuItems: [
        SUB_MENU_ITEMS[SubMenuItemName.TRUSTED_DESTINATIONS],
        ...(isUserAdmin ? [SUB_MENU_ITEMS[SubMenuItemName.WALLET_KEYS]] : []),
      ],
    },
    ...(!isWebTopBarActive ? [MENU_ITEMS[MenuItemName.LEGAL_DISCLOSURES]] : []),
  ];

  return (
    <div className={sharedCss.sidenav}>
      <div className={sharedCss.contents} data-testid="sidenav-contents">
        <div
          key="logo"
          className={sharedCss.logoWrapper}
          data-testid="logo-or-mark"
        >
          <PortoLogoIcon className={sharedCss.brandLogo} />
        </div>
        {!isWebTopBarActive && (
          <CurrentUser
            addedTime={addedTime ?? ''}
            operator={operator}
            orgName={orgName}
            userName={secureName}
          />
        )}
        <div data-testid="sidenav-menu">
          {topMenuItems.map((item) =>
            menuEntriesMapper(item, currentPath, appPrefix),
          )}
        </div>
        <div className={sharedCss.bottomSection}>
          <DevToolsMenuItem
            userEmail={userEmail}
            organizationKeyID={organizationKeyID}
          />
          {bottomMenuItems.map((item) =>
            menuEntriesMapper(item, currentPath, appPrefix),
          )}

          {!isWebTopBarActive && (
            <div
              className={sharedCss.logout}
              onClick={logoutUser}
              data-testid="logout-button"
            >
              <ExitIcon className={sharedCss.icon} />
              <div className={sharedCss.label}>Sign out</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortoSideNav;
