import { transformAddresses } from '../../../../utils/mapAddressToWallets';

import type { OperationDrawer } from 'generated/graphql';

import AddressInfo from '../../../widgets/AddressInfo';
import AmountAndFees from '../../../widgets/AmountAndFees';
import Transaction from '../../../widgets/Transaction';

type OneOffOperationType = Extract<
  OperationDrawer.operation,
  { __typename: 'OneOffOperation' }
>;

type Props = {
  operation: OneOffOperationType;
};
const OneOffOperation = ({ operation }: Props) => {
  const {
    asset: { vault },
    account,
    finalFee,
    maybeAmount,
    maybeTransactionID,
    maybeViewTransactionLink,
    sendingBlockchainAddresses,
  } = operation;

  return (
    <>
      <AmountAndFees amount={maybeAmount} fee={finalFee} />
      <AddressInfo
        addressSectionTitle="Into"
        addresses={transformAddresses(sendingBlockchainAddresses)}
        vaultName={vault.name}
        accountName={account?.displayName}
      />
      <Transaction
        transactionID={maybeTransactionID}
        transactionLink={maybeViewTransactionLink}
      />
    </>
  );
};

export default OneOffOperation;
