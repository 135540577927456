import { usePathname } from 'next/navigation';
import * as React from 'react';

import {
  AnchorageDigitalLogoIcon,
  ExitIcon,
} from '@anchorage/common/dist/components/Icons';
import { useFeatureFlagsContext } from '@anchorage/feature-flags';

import { useUserSessionManager } from '../../../hooks/useUserSessionManager';

import sharedCss from '../styles.module.scss';
import css from './styles.module.scss';

import { MenuItemProps, SideNavProps, TSubMenuHeaderProps } from '../types';

import { FEATURE_FLAGS } from '../../../constants/split';
import Pill from '../../Pill';
import CurrentUser from '../CurrentUser';
import DevToolsMenuItem from '../DevToolsMenuItem';
import {
  MENU_ITEMS,
  MenuItemName,
  SUB_MENU_ITEMS,
  SubMenuItemName,
} from '../constants';
import { menuEntriesMapper } from '../helpers';

// Export unwrapped component for testing
// Having an appPrefix is important when working with NextJS apps.
// Settlements has a /settlements prefix which won't be returned using any NextJS functions
// Additionally NextJS Links or router will automatically append to this
export const ADBSideNav: React.FC<SideNavProps> = ({
  client,
  totalPendingAttributions,
  appPrefix = '',
  permissions: { hasConnectedAppsViewPermissions, hasOrgViewPermissions },
  user: { isUserAdmin, userEmail, secureName, addedTime },
  organization: { name: orgName, operator, organizationKeyID },
}) => {
  const currentPath = usePathname();
  const { useIsFeatureActive } = useFeatureFlagsContext();

  const { logoutUser } = useUserSessionManager(client);

  const [isFlexiblePoliciesEnabled] = useIsFeatureActive(
    FEATURE_FLAGS.CD_FLEXIBLE_ORG_POLICIES,
  );

  const [isAccessManagementFFEnabled] = useIsFeatureActive(
    FEATURE_FLAGS.CD_ACCESS_MANAGEMENT,
  );

  const [isPermissionsM3_1Active] = useIsFeatureActive(
    FEATURE_FLAGS.CD_PERMISSIONS_M3_1,
  );

  const [isWebhooksPageActive] = useIsFeatureActive(
    FEATURE_FLAGS.CD_WEBHOOKS_PAGE,
  );

  const [isShowAPIV1Active] = useIsFeatureActive(FEATURE_FLAGS.CD_SHOW_API_v1);

  const [isWebTopBarActive] = useIsFeatureActive(FEATURE_FLAGS.CD_WTB_1);

  const hasConnectedAppsViewPermissionsOrIsAdmin = isPermissionsM3_1Active
    ? hasConnectedAppsViewPermissions
    : isUserAdmin;

  const apiMenuItem: TSubMenuHeaderProps = React.useMemo(() => {
    if (!MENU_ITEMS[MenuItemName.API]) {
      return {
        Icon: () => <></>,
        appPrefix: '',
        label: '',
        testId: '',
      };
    }
    const apiSubMenuItems = [];

    apiSubMenuItems.push(SUB_MENU_ITEMS[SubMenuItemName.API_V2]);

    if (isShowAPIV1Active) {
      apiSubMenuItems.push(SUB_MENU_ITEMS[SubMenuItemName.API_V1]);
    }

    if (isWebhooksPageActive) {
      apiSubMenuItems.push(SUB_MENU_ITEMS[SubMenuItemName.WEBHOOKS]);
    }

    return {
      ...MENU_ITEMS[MenuItemName.API],
      subMenuItems: apiSubMenuItems,
    } as TSubMenuHeaderProps;
  }, [isWebhooksPageActive, isShowAPIV1Active]);

  // Menu items can either be a menu item (Link to a route) or a header with
  // sub-menu items (Links to routes)
  const topMenuItems: Array<MenuItemProps | TSubMenuHeaderProps> = [
    MENU_ITEMS[MenuItemName.DASHBOARD],
    {
      ...MENU_ITEMS[MenuItemName.ACTIVITY],
      extra:
        totalPendingAttributions && totalPendingAttributions > 0 ? (
          <Pill className={css.activityPill}>{totalPendingAttributions}</Pill>
        ) : undefined,
    },
    MENU_ITEMS[MenuItemName.STATEMENTS],
    MENU_ITEMS[MenuItemName.REPORTS],
    MENU_ITEMS[MenuItemName.TRADE],
    MENU_ITEMS[MenuItemName.DIRECTORY],
    MENU_ITEMS[MenuItemName.SETTLEMENTS],
    apiMenuItem,
  ];

  const isAccessManagementEntryEnabled =
    isAccessManagementFFEnabled &&
    hasOrgViewPermissions &&
    isFlexiblePoliciesEnabled;

  const bottomMenuItems: Array<MenuItemProps | TSubMenuHeaderProps> = [
    {
      ...MENU_ITEMS[MenuItemName.SETTINGS],
      subMenuItems: [
        SUB_MENU_ITEMS[SubMenuItemName.DOCUMENT_DELIVERY],
        SUB_MENU_ITEMS[SubMenuItemName.TRUSTED_SOURCES],
        SUB_MENU_ITEMS[SubMenuItemName.TRUSTED_DESTINATIONS],
        ...(isAccessManagementEntryEnabled
          ? [SUB_MENU_ITEMS[SubMenuItemName.ACCESS_MANAGEMENT]]
          : []),
        ...(isFlexiblePoliciesEnabled
          ? [SUB_MENU_ITEMS[SubMenuItemName.FLEXIBLE_POLICIES]]
          : []),
      ],
    },
    ...(hasConnectedAppsViewPermissionsOrIsAdmin
      ? [
          {
            ...MENU_ITEMS[MenuItemName.CONNECTED_APPS],
          },
        ]
      : []),
    ...(!isWebTopBarActive ? [MENU_ITEMS[MenuItemName.LEGAL_DISCLOSURES]] : []),
    ...(!isWebTopBarActive ? [MENU_ITEMS[MenuItemName.HELP_AND_SUPPORT]] : []),
  ];

  return (
    <div className={sharedCss.sidenav}>
      <div className={sharedCss.contents} data-testid="sidenav-contents">
        <div
          key="logo"
          className={sharedCss.logoWrapper}
          data-testid="logo-or-mark"
        >
          <AnchorageDigitalLogoIcon className={sharedCss.brandLogo} />
        </div>
        {!isWebTopBarActive && (
          <CurrentUser
            addedTime={addedTime ?? ''}
            operator={operator}
            orgName={orgName}
            userName={secureName}
          />
        )}
        <div data-testid="sidenav-menu">
          {topMenuItems.map((item) =>
            menuEntriesMapper(item, currentPath, appPrefix),
          )}
        </div>
        <div className={sharedCss.bottomSection}>
          <DevToolsMenuItem
            userEmail={userEmail}
            organizationKeyID={organizationKeyID}
          />
          {bottomMenuItems.map((item) =>
            menuEntriesMapper(item, currentPath, appPrefix),
          )}
          {!isWebTopBarActive && (
            <div
              className={sharedCss.logout}
              onClick={logoutUser}
              data-testid="logout-button"
            >
              <ExitIcon className={sharedCss.icon} />
              <div className={sharedCss.label}>Sign out</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ADBSideNav;
