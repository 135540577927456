import { ReactNode, createContext, useState } from 'react';

import { reportError } from '@anchorage/common/dist/utils/errors';

export type FunctionType<T> = (value: T) => T;

interface ContextProps {
  getItem: <T>(key: string) => T | undefined;
  setItem: <T>(key: string, value: T) => void;
}

const contextInitialValue = {
  getItem: () => undefined,
  setItem: <T,>(_key: string, _value: T | FunctionType<T>) => {},
};

const isServer = typeof window === 'undefined';

export const LocalStorageContext =
  createContext<ContextProps>(contextInitialValue);

export const LocalStorageProvider = ({ children }: { children: ReactNode }) => {
  const [storedValue, setValue] = useState(() => {
    try {
      return (!isServer && window.localStorage) ?? {};
    } catch (error) {
      reportError(error as Error);
      return {};
    }
  });

  const getItem = <T,>(key: string): T | undefined => {
    try {
      const item = storedValue[key as keyof typeof storedValue];
      return item ? (JSON.parse(item) as T) : undefined;
    } catch (error) {
      reportError(error as Error);
      return undefined;
    }
  };

  const setItem = <T,>(key: string, value: T | FunctionType<T>) => {
    try {
      const item = storedValue[key as keyof typeof storedValue];
      const parsedItem = item === undefined ? item : (JSON.parse(item) as T);
      const valueToStore =
        value instanceof Function ? value(parsedItem) : value;

      setValue((prevState) => ({
        ...prevState,
        [key]: JSON.stringify(valueToStore),
      }));
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      reportError(error as Error);
    }
  };

  return (
    <LocalStorageContext.Provider value={{ getItem, setItem }}>
      {children}
    </LocalStorageContext.Provider>
  );
};
