import { cva } from 'class-variance-authority';

export const textVariants = cva([], {
  variants: {
    variant: {
      strong: ['text-color-comp-avatar-strong-on-container'],
      muted: ['text-color-comp-avatar-muted-on-container'],
    },
    size: {
      tiny: ['text-sys-size-12'],
      xsmall: ['text-sys-size-12'],
      small: ['text-sys-size-14'],
      medium: ['text-sys-size-14'],
      large: ['text-sys-size-20'],
      huge: ['text-sys-size-28'],
    },
  },
  defaultVariants: {
    variant: 'strong',
    size: 'small',
  },
});
