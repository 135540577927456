import { useEffect, useState } from 'react';

/**
 * **Note:**
 * There were some attempts with different approaches to read the title from the page.
 * i.e.: Using the events from router with and without the `isReady` flag.
 *
 * It seems that these router events can be dispatched before the title changes. So we would
 * have inconsistent results displaying the title of the page in this component.
 * Sometimes it would be a blank text and another times it would be displayed the title
 * of the previous page visited.
 *
 * More context here: https://github.com/anchorlabsinc/anchorage/pull/92334#issuecomment-2328439275
 */
export const useDocumentTitle = () => {
  const [title, setTitle] = useState(document.title);

  useEffect(() => {
    const headElement = document.querySelector('head');

    setTitle(document.title);

    const config = { attributes: true, childList: true, subtree: true };

    const observer = new MutationObserver((mutations) => {
      const hasTitleChanges = mutations.some(
        (mutation) => mutation.target.nodeName === 'TITLE',
      );

      hasTitleChanges && setTitle(document.title);
    });

    observer.observe(headElement as HTMLElement, config);

    return () => {
      observer.disconnect();
    };
  }, []);

  return title;
};
