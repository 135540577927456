import { CurrentUserContext } from 'context/CurrentUserProvider';
import { useContext } from 'react';

import { UIDeveloperError } from '@anchorage/common/dist/utils/errors';
import { ORG_OPERATOR_TYPES } from '@anchorage/frontoffice/constants/app';
import useIsPortoApp from '@anchorage/frontoffice/utils/useIsPortoApp';

import { AppInfoQuery } from 'generated/graphql';
import { UserType } from 'generated/graphql';

type CurrentUser = AppInfoQuery['currentUser'];
type Organization = AppInfoQuery['organization'];

export type ReturnType = {
  isPortoOrg: boolean;
  currentUser: CurrentUser & {
    isAdmin: boolean;
  };
  organization: Organization;
};

export default function useCurrentUser(): ReturnType {
  const currentUserInfo: AppInfoQuery | null = useContext(CurrentUserContext);

  if (!currentUserInfo) {
    throw new UIDeveloperError(
      'You must wrap your components in a CurrentUserProvider',
    );
  }

  const { currentUser, organization } = currentUserInfo;
  const isAdmin: boolean = currentUser.userType === UserType.ADMIN;

  return {
    isPortoOrg:
      useIsPortoApp() || organization.operator === ORG_OPERATOR_TYPES.SELF,
    currentUser: {
      ...currentUser,
      isAdmin,
    },
    organization,
  };
}
