import { createContext, useContext } from 'react';

import useIsPortoApp from '../../utils/useIsPortoApp';

import { AppUserQuery, useAppUserQuery } from '../../generated/graphql';

import { ORG_OPERATOR_TYPES } from '../../constants/app';

export type AppUser = AppUserQuery & {
  isPortoOrg: boolean;
  isLoading: boolean;
};

export const AppUserContext = createContext<AppUser | null>(null);

export const AppUser = ({ children }: { children: JSX.Element }) => {
  const { data, loading: isLoading } = useAppUserQuery();
  const isPortoApp = useIsPortoApp();

  return (
    <AppUserContext.Provider
      value={
        data
          ? {
              ...data,
              isPortoOrg:
                isPortoApp ||
                data.organization.operator ===
                  (ORG_OPERATOR_TYPES.SELF as string),
              isLoading,
            }
          : null
      }
    >
      {children}
    </AppUserContext.Provider>
  );
};

export const useAppUser = () => useContext(AppUserContext);
