import cn from 'classnames';
import * as React from 'react';

import {
  AnchorageLogoOutlineIcon,
  AtlasIcon,
  ErrorIcon,
  PortoIcon,
  SecurityIcon,
} from '@anchorage/common/dist/components/Icons';
import Text from '@anchorage/common/dist/components/Text';

import useCurrentUser from 'hooks/useCurrentUser';

import css from './styles.module.scss';

type AddressDetailProps = {
  description: string;
  error?: boolean;
  Icon: React.ElementType;
};

export const AddressDetail = ({
  Icon,
  description,
  error = false,
}: AddressDetailProps) => {
  const iconClasses = cn({
    [css.icon!]: !error,
    [css.errorIcon!]: error,
  });
  const detailClasses = cn({
    [css.details!]: !error,
    [css.errorDetails!]: error,
  });
  return (
    <div className={css.addressDetails}>
      <Icon className={iconClasses} />
      <Text className={detailClasses} size="small">
        {description}
      </Text>
    </div>
  );
};

type AddressBadgesType = {
  isAnchorageVault?: boolean;
  isTrusted?: boolean;
  trustedCounterpartyName?: string;
};

const AddressBadges = ({
  isTrusted,
  isAnchorageVault,
  trustedCounterpartyName,
}: AddressBadgesType) => {
  const { isPortoOrg } = useCurrentUser();
  const isUntrustedDestination =
    (isTrusted && isAnchorageVault) !== undefined &&
    !isTrusted &&
    !isAnchorageVault &&
    !trustedCounterpartyName;

  const VaultAddressDetail = isPortoOrg ? (
    <AddressDetail description="Porto vault" Icon={PortoIcon} />
  ) : (
    <AddressDetail
      description="Anchorage Digital vault"
      Icon={AnchorageLogoOutlineIcon}
    />
  );

  return (
    <>
      {isUntrustedDestination && (
        <AddressDetail
          error
          description="Untrusted destination"
          Icon={ErrorIcon}
        />
      )}
      {isTrusted && (
        <AddressDetail description="Trusted destination" Icon={SecurityIcon} />
      )}
      {isAnchorageVault && VaultAddressDetail}
      {trustedCounterpartyName ? (
        <AddressDetail
          description={`Trusted counterparty: ${trustedCounterpartyName}`}
          Icon={AtlasIcon}
        />
      ) : null}
    </>
  );
};

export default AddressBadges;
