import { usePathname } from 'next/navigation';
import { ReactNode, createContext } from 'react';

import { LOGIN_PATH } from '@anchorage/frontoffice/utils/routes';

import type { AppInfoQuery } from 'generated/graphql';

export const CurrentUserContext = createContext<AppInfoQuery | null>(null);

export function CurrentUserProvider({
  currentUserInfo,
  children,
}: {
  currentUserInfo: AppInfoQuery | null;
  children: ReactNode;
}) {
  const pathname = usePathname();

  if (pathname === LOGIN_PATH) {
    return <>{children}</>;
  }

  // If we cannot get the current user's information, trigger the error boundary
  // page because we can't render the app without it
  if (!currentUserInfo) {
    // TODO CTD-3206
    //throw new UIDeveloperError('Unable to get current user information 44');
  }

  return (
    <CurrentUserContext.Provider value={currentUserInfo}>
      {children}
    </CurrentUserContext.Provider>
  );
}
