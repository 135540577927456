import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import { useAnalytics } from '@anchorage/analytics';
import { Text } from '@anchorage/common/dist/components';
import { ChevronIcon } from '@anchorage/common/dist/components/Icons';

import css from '../styles.module.scss';

import { SubMenuItems } from '../types';

import MenuItem from '../MenuItem';
import { isItemSelected } from '../helpers';

type Props = React.ComponentPropsWithoutRef<'div'> & {
  currentPath?: string;
  Icon?: React.FC<React.HTMLAttributes<SVGElement>>;
  label: string;
  subMenuItems?: SubMenuItems[];
  testId?: string;
  extra?: React.ReactNode;
  appPrefix: string;
  className?: string;
};

const SubMenuHeader = (props: Props) => {
  const { track } = useAnalytics();
  const {
    testId = '',
    Icon,
    label,
    currentPath,
    subMenuItems,
    // https://github.com/jsx-eslint/eslint-plugin-react/issues/3284
    // eslint-disable-next-line react/prop-types
    className = '',
    children,
    appPrefix,
    extra,
  } = props;
  // See if any of the sub routes are selected. If they are, default to
  // expanding the menu
  const isSubRouteSelected = subMenuItems?.some(
    ({ route }) =>
      currentPath &&
      !!route &&
      isItemSelected({ currentPath, route, appPrefix }),
  );

  const [showSubMenuItems, setShowSubMenuItems] = useState<boolean>(false);

  React.useEffect(() => {
    setShowSubMenuItems(!!isSubRouteSelected);
  }, [isSubRouteSelected, setShowSubMenuItems]);

  const onClick = () => {
    if (isSubRouteSelected) {
      return;
    }

    setShowSubMenuItems((prevValue) => !prevValue);
    track({
      name: 'navigation:client_dashboard:sidenav_menuheader_clicked',
      data: {
        label,
        // showSubMenuItems will be changed in the next render
        action: !showSubMenuItems ? 'open' : 'close',
      },
    });
  };

  const classes = cn({
    [css.menuItem!]: true,
    [css.disabled!]: isSubRouteSelected,
    [className]: Boolean(className),
  });

  const labelClasses = cn([css.label, css.subMenuHeaderLabel]);

  const getSubMenuItems = () => {
    if (children) {
      return React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return null;

        const subMenuClasses = cn({
          [css.menuItem!]: true,
          [css.subMenuItem!]: true,
          [css.noIcon!]: true,
          [css.hide!]: !showSubMenuItems,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          [child.props.className ?? '']: Boolean(child.props.className),
        });

        return React.cloneElement(child, {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore Object literal may only specify known properties, and 'className' does not exist in type 'Partial<unknown> & Attributes'
          className: subMenuClasses,
        });
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return subMenuItems?.map(({ key, ...menuItemProps }, index) => {
      const { route } = menuItemProps;
      const isSelected =
        currentPath && route
          ? isItemSelected({
              currentPath,
              route: route,
              appPrefix,
            })
          : false;
      const subMenuClasses = cn({
        [css.subMenuItem!]: true,
        [css.hide!]: !showSubMenuItems,
      });

      const mainRoute = Array.isArray(route) ? route[0] : route;

      return (
        <MenuItem
          className={subMenuClasses}
          isSelected={isSelected}
          key={menuItemProps.testId ?? index}
          {...menuItemProps}
          appPrefix={appPrefix}
          route={mainRoute}
        />
      );
    });
  };

  // NOTE: We are rendering all of the subMenuItems with display:none
  // so that we can animate them in
  return (
    <>
      <div className={classes} data-testid={testId} onClick={onClick}>
        {Icon && <Icon className={css.icon} title={label} />}
        <div className={labelClasses}>
          <Text>{label}</Text>
          {extra}
        </div>
        <div className={css.chevronIcon}>
          <ChevronIcon
            direction={showSubMenuItems ? 'up' : 'down'}
            className={css.chevronIcon}
          />
        </div>
      </div>
      {getSubMenuItems()}
    </>
  );
};

export default SubMenuHeader;
